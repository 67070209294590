/*! КОНТАКТЫ -------------------------------------------------------- */

.contacts {
  position: relative;
  z-index: -5;
  background-color: $bg_secondary;
  padding: 60px 0px 40px 0px;
}
.contacts__row {
  @extend %flex;
  align-items: flex-start;
}
.contacts__info,
.contacts__map {
  width: 45%;
}
.contacts__map {
  position: relative;
  z-index: -3;
}
.contacts_name {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20px;
}

.contacts__address,
.contacts__phone,
.contacts__open {
  font-size: 20px;
  margin-bottom: 20px;
  .contacts__svg {
    height: 20px;
    width: 20px;
    fill: black;
    margin-right: 20px;
    transition: $duration;
  }
}

.contacts__phone {
  display: block;
  transition: $duration;
  &:hover {
    color: $base_h;
    transition: $duration;
    & .contacts__svg {
      fill: $base_h;
      transition: $duration;
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .contacts__info,
  .contacts__map {
    width: 100%;
  }
  .contacts__row {
    flex-wrap: wrap;
  }
  .contacts {
    background-color: $bg_secondary;
    padding: 20px 0px;
  }
}

/*! SERVICES---------------------------------------------------------------------------------------------------------------- */

.feedback {
  padding: 60px 0px 40px 0px;
}
.feedback__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.feedback__row {
  display: flex;
  justify-content: space-between;
}
.feedback__unit {
  width: 30%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.face1,
.face2,
.face3 {
  height: 140px;
  width: 140px;
  border-radius: 70px;
  margin-bottom: 30px;
  border: 3px solid $borders;
}
.face1 {
  background: url("../assets/images/by.jpg") center center no-repeat;
  background-size: cover;
}
.face2 {
  background: url("../assets/images/kr.jpg") center center no-repeat;
  background-size: cover;
}
.face3 {
  background: url("../assets/images/mt.jpg") center center no-repeat;
  background-size: cover;
}

.feedback__text {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-family: $secondary_font;
}
.feedback_author {
  font-size: 18px;
  margin-bottom: 30px;
}

@media (min-width: 0px) and (max-width: 767px) {
  .feedback {
    padding: 20px 0px;
  }
  .feedback__unit {
    width: 100%;
  }
  .feedback__row {
    flex-wrap: wrap;
  }
}

.up-btn {
  height: 45px;
  width: 45px;
  position: fixed;
  right: 30px;
  bottom: -60px;
  background-color: $borders;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: $duration;
  &:hover {
    transform: translate(0px, -10px);
    transition: $duration;
  }
}
.active_btn {
  bottom: 60px;
  transition: $duration;
}
.up-btn__svg {
  width: 30px;
  height: 30px;
  stroke: white;
}
.up-btn__mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*! ABOUT------------------------------------------------------------- */
.about {
  padding: 60px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}
.about__us {
  font-size: 40px;
  margin-bottom: 30px;
}
.about__description {
  font-weight: lighter;
  font-family: $secondary_font;
  color: $borders;
  margin-bottom: 60px;
  font-size: 18px;
}
.about__we-do,
.about__we-do-heading {
  width: 30%;
  .about__svg {
    fill: $base;
    height: 24px;
    width: 24px;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 18px;
    font-family: $secondary_font;
  }
}
.about__advantages {
  margin-bottom: 60px;
}

@media (min-width: 480px) and (max-width: 991px) {
  .about__we-do-heading {
    width: 100%;
  }
  .about__we-do {
    width: 47%;
  }
  .about {
    flex-wrap: wrap;
    padding: 20px 0px;
  }
}
@media (min-width: 0px) and (max-width: 480px) {
  .about__we-do,
  .about__we-do-heading {
    width: 100%;
  }
  .about {
    flex-wrap: wrap;
    padding: 20px 0px;
  }
  .about__description {
    margin-bottom: 30px;
  }
  .about__advantages {
    margin-bottom: 30px;
  }
  .about__us {
    font-size: 35px;
    margin-bottom: 30px;
  }
}

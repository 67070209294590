/*! BTNS ----------------------------------------------------------------------------------------------------------------------------------------------------- */
.wite-btn {
  padding: 15px 40px;
  font-weight: bold;
  border: 3px solid $base;
  cursor: pointer;
  font-size: 20px;
  color: $base;
  background-color: #fff;
  transition: $duration;
  flex-grow: 0;
  &:hover {
    border: 3px solid $base_h;
    transition: $duration;
    color: $base_h;
    transform: scale(1.03);
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .wite-btn {
    padding: 10px 25px;
    font-size: 14px;
    border: 2px solid $base_h;
  }
}

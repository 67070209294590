/*! КОВОРКИНГ ---------------------------------------------------------------*/

.coworking {
  @extend %flex;
  align-items: flex-start;
  padding: 60px 0px 40px 0px;
}
.coworking__info {
  width: 30%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.coworking__advantage {
  font-family: $secondary_font;
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  margin-bottom: 15px;
  .advantage__svg {
    height: 18px;
    width: 18px;
    fill: $base;
    margin-right: 20px;
  }
}
.coworking__advantage.last {
  margin-bottom: 60px;
}
.coworking__card {
  width: 32%;
  // background-color: $bg_secondary;
  margin-bottom: 30px;
  i {
    color: $base;
  }
}
.coworking__card-image1 {
  height: 350px;
  background: url("../assets/images/ba.jpg") center center no-repeat;
  background-size: cover;
}
.coworking__card-image2 {
  height: 350px;
  background: url("../assets/images/vi.jpg") center center no-repeat;
  background-size: cover;
}
.coworking__card-title {
  font-size: 20px;
  font-family: $secondary_font;
  padding: 10px 20px;
  border-top: 1px solid $borders;
  border-left: 1px solid $borders;
  border-right: 1px solid $borders;
}
.coworking__card-discription {
  font-family: $secondary_font;
  padding: 10px 20px 20px 20px;
  border: 1px solid $borders;
  .coworking__done {
    height: 16px;
    width: 20px;
    fill: $base;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .coworking__info {
    width: 100%;
  }
  .coworking__card {
    width: 48%;
  }
  .coworking {
    flex-wrap: wrap;
    padding: 20px 0px;
  }
}
@media (min-width: 0px) and (max-width: 480px) {
  .coworking__info {
    width: 100%;
  }
  .coworking__card {
    width: 100%;
  }
  .coworking {
    flex-wrap: wrap;
    padding: 20px 0px;
  }
}

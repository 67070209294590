/*! SERVICES---------------------------------------------------------------------------------------------------------------- */
.services {
  padding: 60px 0px 40px 0px;
  background-color: $bg_secondary;
}
.sevices__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services__equipment {
  display: flex;
  justify-content: space-between;
}
.services__unit {
  text-align: center;
  width: 30%;
  background-color: #fff;
  border: 1px solid $borders;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}
.services__image1 {
  height: 400px;
  background: url("../assets/images/fi.jpg") center center no-repeat;
  background-size: cover;
}
.services__image2 {
  height: 400px;
  background: url("../assets/images/pu.jpg") center center no-repeat;
  background-size: cover;
}
.services__image3 {
  height: 400px;
  background: url("../assets/images/or.jpg") center center no-repeat;
  background-size: cover;
}
.services__model {
  font-size: 20px;
  padding: 10px 0px 10px 0px;
  width: 100%;
}
.services__country {
  color: $borders;
  font-family: $secondary_font;
  font-size: 16px;
  padding: 0px 0px 5px 0px;
  width: 100%;
  border-bottom: 1px solid $borders;
}
.services__color,
.services__desctiption {
  padding: 10px 0px;
  font-size: 18px;
  font-family: $secondary_font;
  border-bottom: 1px solid $borders;
}
.services__desctiption {
  padding: 10px 10px;
  font-size: 14px;
  background-color: $bg_secondary;
}
.services__price {
  font-size: 30px;
  padding: 15px 0px;
  p {
    font-size: 15px;
    font-family: $secondary_font;
  }
  .price__svg {
    height: 25px;
    width: 25px;
    fill: black;
  }
}
.services__price .services__unit img {
  width: 100%;
}

@media (min-width: 0px) and (max-width: 767px) {
  .services {
    padding: 20px 0px;
  }
  .services__unit {
    width: 100%;
  }
  .services__equipment {
    flex-wrap: wrap;
  }
}

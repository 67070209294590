/* ! FOOTER -----------------------------------------------*/
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: $borders;
  a {
    color: $borders;
  }
  font-family: $secondary_font;
}
.creator {
  a {
    color: #000;
  }
}

/*! BANNER ---------------------------------------------------------------------------------------------------------------------------------------------------- */

.banner {
  margin-top: 60px;
  padding: 80px 0px 60px 0px;
  background: url("../assets/images/bg.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-size: 60px;
    margin-bottom: 60px;
  }
  h3 {
    font-size: 18px;
    width: 420px;
    margin-bottom: 60px;
    font-weight: lighter;
    font-family: $secondary_font;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .banner {
    padding-bottom: 50px;
    h2 {
      font-size: 35px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 14px;
      margin-bottom: 30px;
      width: 260px;
    }
  }
}

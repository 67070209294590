header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 99.9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  font-size: 15px;
}

.header__logo {
  position: relative;
  z-index: 11;
  cursor: pointer;
  .header__logo-svg {
    fill: $base;
    height: 40px;
    width: 150px;
    transition: $duration;
    &:hover {
      fill: $base_h;
      transition: $duration;
    }
  }
}
.header__links {
  font-weight: 600;
  background-color: white;
  transition: $duration;
  a {
    padding: 0px 5px;
    transition: $duration;
    &:hover {
      color: $base_h;
      transition: $duration;
    }
    &.active {
      color: $base_h;
    }
  }
}
.header__social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 11;
}
.header__phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  .header__phone-svg {
    height: 15px;
    width: 15px;
    transition: $duration;
    transition: $duration;
    margin-right: 10px;
  }
  span {
    transition: $duration;
    margin-right: 10px;
  }
  &:hover {
    .header__phone-svg {
      fill: $base_h;
      transition: $duration;
    }
    span {
      color: $base_h;
      transition: $duration;
    }
  }
}
.header__background {
  display: none;
}
.header__burger {
  display: none;
  position: relative;
  transition: $duration;
  cursor: pointer;
  .header__burger-line1,
  .header__burger-line2,
  .header__burger-line3 {
    transition: $duration;
    height: 3px;
    width: 20px;
    margin: 5px;
    background: #000;
  }
  &:hover .header__burger-line1,
  &:hover .header__burger-line2,
  &:hover .header__burger-line3 {
    background: $base_h;
    transition: $duration;
  }
  &.open .header__burger-line1 {
    transform: translate(0, 8px) rotate(45deg);
    // transform: rotate(45deg);
    transition: $duration;
  }
  &.open .header__burger-line2 {
    transform: rotate(-45deg);
    transition: $duration;
  }
  &.open .header__burger-line3 {
    opacity: 0;
    transition: $duration;
  }
  .header__burger-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .header__background {
    display: block;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .header__burger {
    display: block;
  }
  .header__links {
    position: absolute;
    top: -300px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    transition: $duration;
    z-index: 5;
    a {
      padding: 10px 20px;
      font-size: 20px;
      border-bottom: 1px solid $borders;
    }
  }
  .header__links.open {
    top: 60px;
    transition: $duration;
  }
  .header__logo {
    .header__logo-svg {
      height: 30px;
      width: 110px;
    }
  }
}

@media (min-width: 0px) and (max-width: 479px) {
  .header__social span {
    display: none;
  }
}

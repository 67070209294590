/* CONTAINER -------------------------------------------------------*/

//? ЗДЕСЬ
// отступы на брейк поинтах
// базовый размер шрифта

// верхний отступ два падинга рамки
.container {
  width: 100%;
  padding: 0px 80px;
}
@media (min-width: 1400px) {
  .container {
    width: 1400px;
    margin: 0 auto;
  }
}
@media (min-width: 480px) and (max-width: 1024px) {
  .container {
    padding: 0px 40px;
  }
}
@media (min-width: 0px) and (max-width: 479px) {
  .container {
    padding: 0px 20px;
  }
}

/* GLOBAL VARIABLES-------------------------------------------------------*/

//! ЦВЕТА
// основной акцентный
$base: rgb(218, 64, 64);
// основной акцентный ховер
$base_h: rgb(220, 97, 97);
// дополнительный фон
$bg_secondary: rgb(241, 241, 241);
// рамки и подчеркивания
$borders: rgb(201, 201, 201);
// цвет шрифта
$font_color: black;

//! ШРИФТЫ
@font-face {
  font-family: "PoiretOne";
  src: url("../assets/fonts/PoiretOne.woff2") format("woff2"),
    url("../assets/fonts/PoiretOne.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat.woff2") format("woff2"),
    url("../assets/fonts/Montserrat.woff") format("woff");
}
$base_font: "PoiretOne";
$secondary_font: "Montserrat";

//! АНИМАЦИЯ
$duration: 200ms;

//! ПОВТОРЯЮЩИЕСЯ СТИЛИ
%flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//! ГЛОБАЛЬНЫЕ СТИЛИ
body {
  font-family: $base_font;
  color: $font_color;
  a {
    color: $font_color;
  }
}

//! СТИЛТ ПОСТОЯННЫХ ЭЛЕМЕНТОВ
// заголовок секции
.section-heading {
  color: $borders;
  font-size: 25px;
  margin-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 540px) {
  .section-heading {
    color: $borders;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.section__description {
  font-size: 35px;
  font-weight: 100;
  margin-bottom: 40px;
}

/* DASH -----------------------------------------*/
.dash {
  height: 10px;
  width: 40px;
  background-color: $base;
  margin-bottom: 60px;
}
@media (min-width: 0px) and (max-width: 540px) {
  .dash {
    margin-bottom: 30px;
  }
}

.dash_little {
  height: 10px;
  width: 40px;
  background-color: $base;
  margin-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 540px) {
  .dash_little {
    margin-bottom: 20px;
  }
}
